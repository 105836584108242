import { Button } from "bootstrap/dist/js/bootstrap.bundle";
import React from "react";
import { Container, Form, NavDropdown, Navbar } from "react-bootstrap";
import "./nav.css";
import topImg from "./top.png";
import phn from "./phone.png";
function Nav() {
  return (
    <nav className="navbar navbar-expand-sm bg-none navbar-dark">
      <div className="container">
        <a className="navbar-brand" href="#">
          Portfolio.
        </a>
        <div className="collapse navbar-collapse" id="collapsibleNavbar">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href="#">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Projects
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                About Us
              </a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
              >
                Download
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" href="#">
                    Image/Video
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    CV
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    3D Model
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="d-flex">
          <a className="navbar-brand" href="#">
            <img className="ph" src={phn} />
          </a>
          <a className="navbar-brand" href="#">
            Contact
          </a>
          <div className="rabBox">
            <a className="navbar-brand" href="#">
              <img className="rab" src={topImg} />
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
