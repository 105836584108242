import React from "react";
import "./home.css";
import bg from "./pp2.png";
function Home() {
  return (
    <div className="container home">
      <div className="row">
        <div className="col-md-9 sm-6 bgimg">
          <img className="bg" src={bg} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6  txtBox">
          <div className="row">
            <div className="col-md-12 hi">
              <h4>
                Hello,<span className="redLetter"> I</span> am{" "}
              </h4>
            </div>
            <div className="col-md-12 nam">
              <h1 className="intro">
                <span className="redLetter"> R</span>abin A
                <span className="redLetter">le</span>,
              </h1>
            </div>
          </div>
        </div>

        <div className="col-md-6 txtBox">
          <h1 className="intro">
            a <span className="jobs"> Web Developer</span>,
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Home;
